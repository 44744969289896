import compose from 'lyjs/es/fp/compose'
import createRestfulApi from 'lyjs/es/request/createRestfulApi'
import {headerAuth} from 'lyjs/es/request/HeaderAuth'

import {
  AUTH_HEADER_NAME,
  PRISM_API_ENDPOINT,
  GALAXY_API_ENDPOINT,
  POLICY_ENDPOINT,
  HUKOU_AUTH_URL
} from 'services/constants'

function responseErrorInterceptor(res) {
  const {
    data: {
      code,
      error
    } = {}
  } = res

  if (code && (code < 200 || code > 299)) {
    const err = new Error(error)

    Object.assign(err, res, {
      status: code
    })

    return Promise.reject(err)
  }

  return res
}

export const Prism = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: PRISM_API_ENDPOINT
}, {
  response: [responseErrorInterceptor]
})

export const Galaxy = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: GALAXY_API_ENDPOINT
}, {
  response: [responseErrorInterceptor]
})

export const Hukou = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: HUKOU_AUTH_URL
}, {
  response: [responseErrorInterceptor]
})

export const Policy = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: POLICY_ENDPOINT
}, {
  response: [responseErrorInterceptor]
})

export function authorize(token) {
  Prism.authorize(token)
  Galaxy.authorize(token)
  Hukou.authorize(token)
}

export function authorizePolicy(token) {
  Policy.authorize(`Bearer ${token}`)
}
